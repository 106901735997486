import React from "react";
import { Link } from "gatsby";

export const data_apps = {
  what_we_do: {
    title: "Zwracamy uwagę na:",
    text: [
      <>
        <b>Profesjonalne badania UX</b> - Rozwój funkcjonalności i użyteczności
        produktu daje satysfakcję z wykonanego projektu. Przeprowadzamy testy
        poprawności działania, aby mieć pewność, że Twoja aplikacja będzie
        spełniała swoje zadania.
      </>,
      <>
        <b>Przejrzysty i nowoczesny design</b> - aplikacje mobilne, które
        tworzymy, odpowiadają na najnowsze trendy, a do tego są czytelne i łatwe
        w obsłudze. Innowacyjne rozwiązania i nowoczesne technologie nie mają
        przed nami tajemnic.
      </>,
      <>
        <b>Tworzenie rozwiązań cross-platform</b> - spójność w tworzeniu
        aplikacji to dla nas jedna z ważniejszych kwestii. Nasze projekty pasują
        do innych rozwiązań i działań, które stosuje marka - cechuje je
        wieloplatformowość.
      </>,
      <>
        <b>Moduły i szybką możliwość rozbudowy</b> - sprawimy, że Twoja
        aplikacja będzie zawierać wszystko, czego aktualnie potrzebujesz. Chcesz
        poszerzyć serwis o nowe moduły lub treści? Nie ma problemu! W naszych
        projektach jest to możliwe.
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Planowanie",
        list: [
          "Opracowanie briefu",
          "Audyt obecnych zasobów",
          "Analiza konkurencyjnych rozwiązań",
          "Dobór technologii do założeń projektu",
        ],
      },
      {
        title: "Projektowanie graficzne",
        list: [
          "Koncepcja funkcjonalna",
          "Opracowanie Makiet",
          "Projektowanie Widoków",
          <Link to="/uslugi/web-design">
            Zobacz szczegółowy proces projektowania graficznego
          </Link>,
        ],
      },
      {
        title: "Wdrożenie",
        list: [
          "Dobór usługi hostingowej do zastosowanej technologii",
          "Utworzenie repozytorium plików",
          "Utworzenie struktury projektu",
          "Współpraca przy UX/UI",
          "Programowanie",
          "Współpraca przy treściach oraz SEO",
        ],
      },
      {
        title: "Obsługa",
        list: [
          "Aktualizacje systemu",
          "Wdrożenie udoskonaleń funkcjonalnych",
          "Tworzenie kopii bezpieczeństwa",
          "Monitorowanie działania systemu",
        ],
      },
    ],
  },
};
